import { useState } from "react";
import ReactPainter from "react-painter";
import './style.css';
import useWindowSize from "../../../hooks/useWindowSize";

const Notes = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [key, setKey] = useState(1);
    const { height, width } = useWindowSize();

    const onSaveBlob = (blob) => {
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl); // Set the URL as the image source
    }

    const clearCanvas = () => {
        setKey((prev) => prev + 1);
    }
    return (
        <div className="page">
            <div className="notes-container">
                <ReactPainter
                    key={key}
                    image={imageSrc}
                    width={width - 50}
                    height={height - 50}
                    onSave={blob => onSaveBlob(blob)}
                    render={({ triggerSave, canvas }) => (
                        <div>
                            <div className="canvas-btns">
                                <button className="btn btn-outline-secondary mx-4" onClick={clearCanvas}>Clear</button>
                                <button className="btn btn-p" onClick={triggerSave}>Save</button>
                            </div>
                            <div>{canvas}</div>
                        </div>
                    )}
                />
            </div>
        </div>
    )
}

export default Notes;