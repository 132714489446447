import React, { useEffect, useState } from 'react';
import { useHTTP } from '../../../hooks/useHTTP';
import './style.css';
import Loader from '../../../components/Loader';
import { Constant } from '../../../constants';
import { dateFormat } from '../../../utils/dateFormat';
import { NavLink, useNavigate } from 'react-router-dom';
import { ViewIco } from '../../../assets/svg/Icons';

function Notification() {

    return (
        <div className='page'>
            <div className='card-container mx-4 w-100'>
                <h1 className='f-14'>Notifications</h1>
                <table className='table table-striped table-hover table-responsive reports-table'>
                    <thead>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>


            </div>
        </div>
    );
}

export default Notification; 