const style = {
    height: '20px',
    width: '40px'
}

export const Toggle = ({ intialValue = false, onToggle }) => {
    return (
        <div className={`form-check form-switch`}>
            <input className="form-check-input" style={style} type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={onToggle} />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
        </div>
    )
}