import { createContext, useEffect, useState } from "react";
import { clearSessionWorkshop, getSessionWorkshop } from "../utils/session";
import { useNavigate } from "react-router-dom";
import { Constant } from "../constants";
import { useDispatch } from "react-redux";
import { clearAuth, setAuth } from "../store/features/authSlice";

export const AuthContext = createContext();

const AUTH_DATA = {
    authendicated: false,
    access_token: null,
    expires_at: null,
    refresh_token: null,
    role: ''
}
export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(AUTH_DATA);
    const navigate = useNavigate();
    const dispath = useDispatch();

    useEffect(() => {
        const checkAuth = async () => {
            const sessionData = await getSessionWorkshop();
            // console.log('sessionData', sessionData)
            if (sessionData !== null) {
                dispath(setAuth(sessionData));
                if (sessionData?.role.toLowerCase() === Constant.workshop.toLowerCase()) {
                    navigate('/workshop/existingUser');
                }
            }
        };
        checkAuth();
    }, []);

    const logout = async () => {
        await clearSessionWorkshop();
        dispath(clearAuth());
        navigate('/')
    }

    return (
        <AuthContext.Provider value={{ ...authData, setAuthData, logout }}>
            {children}
        </AuthContext.Provider>
    )
} 